import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints";

const Container = styled.div`
  width: 100%;
  padding: 0 16px;
  
  @media(min-width: ${breakpoints.md}) {
    padding-left: 32px;
    padding-right: 32px;
  }
`;

const ContainerBlock = styled.div`
  width: 100%;
  max-width: 1600px;
  margin: auto;
`;

const ContentContainer = ({children}) => {
  return (
    <Container>
      <ContainerBlock>
        {children}
      </ContainerBlock>
    </Container>
  );
};

export default ContentContainer